import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import Faq from "../../components/Faq"
import PricingTable from "../../components/PricingTable"
import FeaturedCarousel from "../../components/FeaturedCarousel"
import Steps from "../../components/Steps"
import FreeDiscussion from "../../components/FreeDiscussion"
import ChooseState from "../../components/ChooseState"
import ReviewTestimonials from "../../components/ReviewTestimonials"
import HeroSection from "../../components/HeroSection"
import { Guide } from "../../components/Guide"
import AppDownloadBanner from "../../components/AppDownloadBanner"

const LostALovedOne = ({ data, path }) => {
  const { heroSection } = data.markdownRemark.frontmatter

  return (
    <Layout hasSubNav={true} navId="lostALovedOne" loadMap={false}>
      <SEO
        path={path}
        title="Lost A Loved One? - EstatesPlus - Wills, Probate & Letters of Administration. Save up to 90% on legal fees"
        description="Lost A Loved One? Finalise A Deceased Estate Save * We make it easy to get Probate or Letters of Administration. Letters of Administration Grant of Probate Full Legal Service MKI Legal Have Been Featured In How We Can Help You Step 1 Have a Free Discussion with Our Legal Team"
      />

      {/* Hero Section */}
      <HeroSection
        className="subHeader"
        id="lost-a-loved-one-hero"
        formButtonName="lost-a-loved-one"
        bgImage={heroSection.bgImage}
        subTitle={heroSection.subTitle}
        buttonLinks={heroSection.buttonLinks}
      >
        <div className="d-flex flex-column mb-4">
          <h1 className="font-black">
            <span className="d-block">Lost A Loved One?</span>
            Finalise A <span className="emphasize-text">Deceased Estate</span>
          </h1>
        </div>
      </HeroSection>

      <section className="bg-light">
        <ReviewTestimonials />
      </section>

      {/* <section className="bg-white">
        <Steps colCount={3} stepsName="lost-a-loved-one">
          <h2 className="font-bold text-center mb-7 d-block">
            How We Can
            <span className="d-inline-block bg-accent px-3 py-2 m-2 text-white">
              Help
            </span>
            You
          </h2>
        </Steps>
      </section> */}

      <section className="bg-white">
        <FreeDiscussion name="lost-a-loved-one" />
      </section>

      <section className="bg-light">
        <div className="container section-gap">
          <ChooseState service="letters" />
        </div>
      </section>

      <section className="bg-white">
        <FeaturedCarousel />
      </section>

      {/* <section className="bg-light">
        <PricingTable tableName="pricing">
          <h2 className="text-center font-bold mb-5">
            Fixed <span className="emphasize-text">Pricing</span>{" "}
            <span className="emphasize-text">Affordable</span> Services
          </h2>
          <h3 className="text-center">
            <span className="d-block d-md-inline-block mb-2 mb-md-0 mr-0 mr-md-2">
              Save up to 80% on Legal Fees*.
            </span>
            <span className="d-block d-md-inline-block mb-2 mb-md-0 mr-0 mr-md-2">
              Pay Later options available.
            </span>
            <span className="d-block d-md-inline-block">Lawyer Approved.</span>
          </h3>
        </PricingTable>
      </section> */}
      <AppDownloadBanner />
      {/* this displays the guide to the deceased estate step by step  & debbie's story*/}
      <Guide />

      <section className="bg-light">
        <Faq name="lost-a-loved-one">
          <div className="col-12 mb-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              {/* Section Title */}
              <h2 className="mb-4 font-bold text-center">
                <span className="d-block d-md-inline-block mb-3 mb-md-0 mr-0 mr-md-3">
                  Frequently Asked{" "}
                </span>
                <span className="bg-accent px-2 text-white">Questions</span>
              </h2>
              {/* Section Subtitle */}
              <p className="font-semibold text-center">
                We’re here to help you navigate through the loss of a loved one
              </p>
            </div>
          </div>
        </Faq>
      </section>
    </Layout>
  )
}

export default LostALovedOne

export const pageQuery = graphql`
  query {
    markdownRemark(
      frontmatter: { templateKey: { eq: "lost-a-loved-one/index" } }
    ) {
      frontmatter {
        heroSection {
          bgImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          subTitle {
            content
          }
          buttonLinks {
            linkText
            linkTo
            linkStyle
            type
          }
        }
      }
    }
  }
`
